import { get, post, put, destroy, downloadBlob } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/trading`
const fetchStockDetails = async (isinId) => get(`${url}/asset-details/${isinId}`)
const fetchMarketDetails = async (isinId, data) => get(`${url}/asset-price-new/${isinId}`, data)
const simpleAssetPrice = async (isinId, data) => get(`${url}/simple-asset-price/${isinId}`, data)
const accountInfo = async () => get(`${url}/account-info`)
const commission = async (data) => post(`${url}/isins/${data.isin_id}/order-commission`, data)

const placeOrder = async (data) => post(`${url}/order`, data)
const modifyOrder = async (data, orderId) => put(`${url}/order/${orderId}`, data)
const cancelOrder = async (orderId) => destroy(`${url}/order/${orderId}`)
const confirmOrder = async (orderId) => post(`${url}/order/${orderId}/confirm-order`)
const fetchTransactions = async (data) => get(`${url}/orders`, data)
const fetchTransactionsCount = async () => get(`${url}/orders/count`)

const fetchTransaction = async (data) => get(`${url}/order/${data.id}`, { currencyLabel: data.currencyLabel })
const fetchSnpMarketDetails = async (data) =>
  get(`${process.env.VUE_APP_API_URL}/companies/${data.companyId}/price-details`, { currency: data.currency })

const scrollTransactions = async (link, payload) => get(`${link}`, payload)
const accountPerformance = async (interval) => get(`${url}/account-performance`, interval)
const subAccountInfo = async () => get(`${process.env.VUE_APP_API_URL}/profile/sub-account-info`)
const accountLedger = async (payload) => get(`${process.env.VUE_APP_API_URL}/profile/account-ledger`, payload)
const accountAllocation = async () => get(`${process.env.VUE_APP_API_URL}/profile/account-allocation`)
const portfolioPosition = async (payload) => get(`${process.env.VUE_APP_API_URL}/profile/portfolio-position`, payload)
const accountSummary = async (data) => get(`${process.env.VUE_APP_API_URL}/profile/account-summary`, data)
const getHoldingStocks = async (data) => get(`${url}/isins/${data.isin_id}/get-holding-stocks`)

const getWithdrawableCash = async () => get(`${process.env.VUE_APP_API_URL}/dam/get-withdrawable-cash`)
const withdrawFunds = async (data) => post(`${process.env.VUE_APP_API_URL}/withdraw-funds`, data)

const downloadReceipt = async (orderId) => downloadBlob(`${url}/order/${orderId}/pdf`)

export default {
  fetchTransactionsCount,
  fetchStockDetails,
  fetchMarketDetails,
  simpleAssetPrice,
  accountInfo,
  commission,
  placeOrder,
  confirmOrder,
  fetchTransactions,
  fetchTransaction,
  fetchSnpMarketDetails,
  scrollTransactions,
  modifyOrder,
  cancelOrder,
  accountPerformance,
  subAccountInfo,
  accountLedger,
  accountAllocation,
  portfolioPosition,
  accountSummary,
  getHoldingStocks,
  getWithdrawableCash,
  withdrawFunds,
  downloadReceipt
}
