<template>
  <div class="relative pt-10 py-10">
    <div
      v-if="!isMobile"
      class="bg-custom-lightPurple-300 dark:bg-custom-purple-400 h-80 w-full drop-shadow absolute top-0 left-0 -z-20" 
      :class="$route.name === 'exploreMaps' ? 'opacity-70' : ''"
    />
    <Container
      wide
      padding="p-0"
    >
      <div v-if="!isMobile">
        <div class="h-40 flex flex-col items-center justify-center">
          <div>
            <SelectedTagSection
              :selected-tags="selectedTags"
              :count="isForumAndDiscussions ? filtrationsStore.forumAndRoomCount : filtrationsStore.count"
              :count-loading="isForumAndDiscussions ? filtrationsStore.forumAndRoomCountLoading : filtrationsStore.countLoading"
              :is-forum-and-discussions="isForumAndDiscussions"
              @focus-tag-input="focusTagInput"
              @remove="removeTag"
            />
          </div>
        <!-- <div
          if="inList"
          class=" text-3xl font-semibold"
        >
          Here you see all our lists, explore, follow, create.
        </div> -->
        </div>
        <div class="shadow dark:shadow-sm-dark rounded">
          <TabButton
            :selected-tab="selectedTab"
            :parent-route="parentRoute"
            @change="changeTab"
          />
          <div
            v-if="filtrationsStore.filterDataLoading"
            class="flex items-center justify-center rounded-b-md shadow-sm dark:shadow-none p-6 bg-white dark:bg-custom-purple-400"
          >
            <CircleLoader />
          </div>
          <div v-else>
            <ExploreFilter
              v-if="selectedTab === 'explore'"
              :show-more="showMore"
              :screener-filters="screenerFilters"
              :applied-filter="appliedFilter"
              :filter-changed="filterChanged"
              @toggle-show-more="toggleShowMore"
              @update-multi-select="updateSector"
              @input-range="updateRange"
              @search="searchCompaniesByFilter"
              @apply="applyFilter"
              @reset="resetFilter"
            />
            <ForumFilter
              v-else-if="selectedTab === 'forumAndDiscussions'"
              @apply="applyFilter"
              @reset="resetFilter"
            />
            <History
              v-else-if="selectedTab === 'history'"
              :history="filtrationsStore.sanitizeHistory"
              @explore="exploreHistory"
            />
          </div>
        </div>
      </div>
      <div
        v-else
      >
        <div class="h-20 flex flex-col items-center justify-center">
          <SelectedTagSection
            :selected-tags="selectedTags"
            :count="isForumAndDiscussions ? filtrationsStore.forumAndRoomCount : filtrationsStore.count"
            :count-loading="isForumAndDiscussions ? filtrationsStore.forumAndRoomCountLoading : filtrationsStore.countLoading"
            :is-forum-and-discussions="isForumAndDiscussions"
            :is-mobile="isMobile"
            @focus-tag-input="focusTagInput"
            @remove="removeTag"
          />
        </div>
        <MobileFilterMenu
          :filter-options="$route.path.includes('forum')? roomsFilterOptions : exploreFilterOptions"
          :show-filter="showFilter"
          :title="filterTitle"
          :is-kyc-pending="isKycPending && isLoggedIn"
          :show-single-filter-menu="showSingleFilterMenu"
          :screener-filters="screenerFilters"
          :tab-name="selectedTab"
          :min-value="minValue"
          :max-value="maxValue"
          :applied-filter="appliedFilter"
          :filter-changed="filterChanged"
          :selected-option="selectedOption"
          :count="filtrationsStore.count"
          :loading="filtrationsStore.filterDataLoading"
          @set-range-value="onSetRangeValue"
          @toggle="$emit('toggle')"
          @toggle-single-menu="toggleSingleMenu"
          @input-range="updateRange"
          @search="searchCompaniesByFilter"
          @update-checkbox="updateCheckbox"
          @apply="applyFilter"
          @reset="resetFilter"
        />
      </div>
    </Container>
  </div>
</template>

<script>
import SelectedTagSection from './_components/SelectedTagSection'
import TabButton from './_components/TabButton'
import ExploreFilter from './_components/ExploreFilter'
import ForumFilter from './_components/ForumFilter'
import History from './_components/History'
import { useFiltrationsStore } from '../../stores/filtrations'
import { useUsersStore } from '../../stores/users'
import eventBus from '@/js/eventbus'
import { isMobile } from '@/js/helpers/mobile'
import MobileFilterMenu from './_components/MobileFilterMenu'
export default {
  name: 'FiltrationsModule',
  components: {
    SelectedTagSection,
    MobileFilterMenu,
    TabButton,
    ExploreFilter,
    ForumFilter,
    History,
  },
  props: {
    showFilter: { type: Boolean, default: false },
    source: { type: String, default: '' },
    
  },
  emits: ['toggle'],
  setup() {
    const filtrationsStore = useFiltrationsStore()
    const usersStore = useUsersStore()
    return {
      filtrationsStore,
      usersStore
    }
  },
  data() {
    return {
      showMore: false,
      filterTitle: 'Filter',
      showSingleFilterMenu: false,
      minValue: null,
      maxValue: null,
      selectedOption: null,
      appliedFilter: false,
      filterChanged: false,
      exploreFilterOptions: [
        {
          id: 1,
          name: 'Countries',
          type: 'checkbox'
        },
        {
          id: 2,
          name: 'Sectors',
          type: 'checkbox'
        },      
        {
          id: 3,
          name: 'Marketcap',
          type: 'slider'
        },
        {
          id: 4,
          name: 'Price',
          type: 'slider'
        },
        {
          id: 5,
          name: 'Shares',
          type: 'slider'
        }
      ],
      roomsFilterOptions: [
        {
          id: 1,
          name: 'Room Type',
          type: 'radio'
        },
        {
          id: 2,
          name: 'Time span',
          type: 'slider'
        }
      ]
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    },
    isLoggedIn() {
      return this.usersStore.isLoggedin
    },
    isKycPending () {
      return this.usersStore?.user?.is_kyc_pending
    },
    inList() {
      return this.$route.path.includes('explore/lists')
    },
    selectedTags() {
      return this.filtrationsStore.selectedTags
    },
    resultSubType() {
      if (this.$route.name === 'roomResults') {
        return 'rooms'
      } else if (this.$route.name === 'threadResults') {
        return 'threads'
      }
      return ''
    },
    screenerFilters() {
      return this.filtrationsStore.screenerFilters
    },
    getFiltrationUrlQuery() {
      return this.filtrationsStore.getFiltrationUrlQuery
    },
    // selectedTab () {
    //   return this.$route.name
    // },
    parentRoute() {
      if (this.$route.matched.length > 1) {
        return this.$route?.matched[0]?.name
      }
      return this.$route.name
    },
    selectedTab() {
      if (this.$route.path.includes('forum')) {
        return 'forumAndDiscussions'
      } else if (this.$route.path.includes('history')) {
        return 'history'
      }

      return 'explore'
    },
    isForumAndDiscussions() {
      if (this.$route.fullPath.includes('/forum-and-discussion')) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    showFilter(newValue) {
      if (newValue === true && this.isMobile) {
        const chatBox = document.getElementById('hubspot-messages-iframe-container')
        if (chatBox) {
          chatBox.id = 'hideHubspot'
        }
      } else if (newValue === false && this.isMobile) {
        const chatBox = document.getElementById('hideHubspot')
        if (chatBox) {
          chatBox.id = 'hubspot-messages-iframe-container'
        }
      }
    }
  },
  mounted() {
    this.filtrationsStore.fetchFilterData()
    this.filtrationsStore.fetchHistory(this.usersStore.user.id)
    // check if there is a query in the vue router
    const routerQuery = this.$route.query.filtration
    if (routerQuery) {
      this.filtrationsStore.setFilterDataFromUrlQuery(routerQuery)
      if (this.isForumAndDiscussions) {
        this.filtrationsStore.fetchForumAndRoomCount('filter-stats', this.resultSubType)
      } else {
        this.filtrationsStore.fetchCount('filter-stats', 'companies')
      }
    } else {
      if (this.isForumAndDiscussions) {
        this.filtrationsStore.fetchForumAndRoomCount('filter-stats', this.resultSubType)
      } else {
        this.filtrationsStore.fetchCount('filter-stats', 'companies')
      }
    }

    if (this.parentRoute) {
      this.selectedTab = this.parentRoute
    }
    eventBus.$on('on-filtration-change', () => {
      this.filtrationsStore.fetchCount('filter-stats', 'companies')
      if (this.isForumAndDiscussions) {
        this.filtrationsStore.fetchForumAndRoomCount('filter-stats', this.resultSubType)
      }
      this.pushFilterToRouter()
    })
  },
  beforeUnmount() {
    const chatBox = document.getElementById('hideHubspot')
    if (chatBox) {
      chatBox.id = 'hubspot-messages-iframe-container'
    }
  },
  unmounted() {
    this.filtrationsStore.resetScreenerFilter()
    eventBus.$off('on-filtration-change')
  },
  methods: {
    async onSetRangeValue(payload, event, source) {
      let range = payload.screenerFilter.data.defaultRange
      if(source === 'min') {
        this.minValue = event? event : range[0]
        if(!this.maxValue) this.maxValue = range[1]
        payload.preselect = event? [event, this.maxValue] : [range[0], range[1]]
      } else if (source === 'max') {
        this.maxValue = event? event : range[1]
        if(!this.minValue) this.minValue = range[0]
        payload.preselect = event? [this.minValue, event] : [range[0], range[1]]
        
      }
       
      await this.updateRange(payload, 'minmax')
    },

    changeTab(tab) {
      this.$router.replace({ name: tab })
      this.selectedTab = tab
    },
    toggleShowMore() {
      this.showMore = !this.showMore
    },
    removeTag(tag) {
      this.filtrationsStore.removeTag(tag)
    },
    async updateCheckbox(payload, item) {
      let preselect = payload.screenerFilter.preselect ?? []
      if(preselect.includes(item)) {
        preselect = preselect.filter(element => element.id !== item.id) 
      } else preselect.push(item)
      payload.preselect = preselect
      await this.updateSector(payload)
    },
    async updateSector(payload) {
      await this.filtrationsStore.updateFilterData(payload)
      this.searchCompaniesByFilter()
      this.filterChanged = true
    },
    async updateRange(payload, source) {
      // let range = payload.screenerFilter.data.defaultRange

      if(source !== 'minmax') {
        this.minValue = payload.preselect[0]
        this.maxValue = payload.preselect[1] 
      }
      
      await this.filtrationsStore.updateFilterData(payload)
      this.filterChanged = true
    },
    async searchCompaniesByFilter() {
      const self = this
      const screenerFilters = []
      self.screenerFilters.map((screenerFilter) => {
        screenerFilter.selected = screenerFilter.preselect ? screenerFilter.preselect : null
        screenerFilters.push(screenerFilter)
      })
      self.filtrationsStore.addScreenerFilter(screenerFilters)
      // self.pushFilterToRouter()
      // eventBus.$emit('on-filtration-change')
    },
    exploreHistory(data) {
      if (!data.tags && data.tags.length < 1) return
      this.filtrationsStore.resetScreenerFilter()
      this.filtrationsStore.setSelectedTagsFromHistory(data.tags)
      this.filtrationsStore.fetchCount('filter-stats', 'companies')
      this.$router.push({
        name: 'exploreResults',
        query: { filtration: JSON.stringify(data.tags) }
      })
    },
    focusTagInput() {
      eventBus.$emit('focusTagInput')
    },
    resetFilter() {
      this.minValue = null
      this.maxValue = null
      this.filtrationsStore.resetScreenerFilter()
      eventBus.$emit('on-filtration-change')
      this.appliedFilter = false
      this.filterChanged = false
    },
    async applyFilter() {
      await this.filtrationsStore.resetTags()
      if (this.filtrationsStore.selectedScreenerFilters.length > 0) {
        await this.filtrationsStore.setTags()
      }
      eventBus.$emit('on-filtration-change')
      this.filtrationsStore.setHistory()
      this.appliedFilter = true
      this.filterChanged = false
    },
    pushFilterToRouter() {
      if(this.filtrationsStore.selectedTags.length === 0) {
        this.$router.replace({ query: null })
      } else {
        this.$router.replace({ query: { filtration: this.getFiltrationUrlQuery } })
      }
    },
    toggleSingleMenu(option) {
      this.showSingleFilterMenu = !this.showSingleFilterMenu
      this.minValue = null
      this.maxValue = null
      if(this.showSingleFilterMenu) this.filterTitle = option.name
      else this.filterTitle = 'Filter'

      this.selectedOption = option
    }
  }
}
</script>
<style lang="scss">
#hideHubspot{
  display: none !important;
 }
</style>
