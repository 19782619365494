import { post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/investment-members`


const updateInvestmentMember = async (id, payload) => post(`${url}/${id}`, payload)

export default {
  updateInvestmentMember
}
