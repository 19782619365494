<template>
  <BaseButton
    class="focus:outline-none absolute bottom-0 right-0 z-10"
    no-padding
    @click="$refs.addProfile.click()"
  >
    <input
      ref="addProfile"
      type="file"
      accept="image/*"
      class="hidden"
      @change="$emit('on-image-change',$event)"
    >
    <Icon
      name="camera"
      solid
      class=" text-lg w-8 h-8 flex items-center justify-center bg-custom-purple-400 dark:bg-custom-purple-300 text-white rounded-full border-solid border-custom-purple-400 dark:border-custom-purple-300 border-4"
    />
  </BaseButton>
</template>
  
  <script>
  
  export default {
    name: 'ProfileImageUploader',
    props: {
      profileImage: {
        type: String,
        default: ''
      }
    },
  emits: [
  'on-image-change'
  ],
    data () {
      return {
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  