<template>
  <div
    class="flex flex-col justify-center"
    :class="minified ? (subtitle ? 'py-1' : 'py-3') : ' py-4 pl-10 mdx:pl-16'"
    @click="$emit('click', $event)"
  >
    <div class="flex flex-col">
      <div class="flex items-center gap-2 md:gap-0">
        <div class="relative">
          <CompanyAvatar
            :company="company"
            :image-url="imageUrl"
            :size="minified ? ((company && company.logo) || imageUrl ? 45 : 60) : 120"
            :minified="minified"
            class="cursor-pointer"
            :default-class="[edit ? ' ' : '', minified ? 'w-12 h-12' : 'w-20 h-40']"
            @click="$emit('click', $event)"
          />
          <EditButtonCircle
            v-if="editMode && !minified"
            :edit="edit"
            class="absolute -right-4 top-2 z-20"
            @click="$emit('edit-logo')"
          />
          <div
            v-if="company?.logo && edit"
            class="absolute right-5 bottom-2 z-20 rounded-full w-8 h-8 flex justify-center items-center p-1 cursor-pointer"
            :class="'bg-custom-orange-400 hover:bg-custom-orange-300 text-white'"
            @click="$emit('delete-logo')"
          >
            <Icon
              :name="'trash'"
              class="text-lg"
              solid
            />
          </div>
          <SquareImageUploaderContainer
            v-if="edit && !minified"
            class="absolute bottom-2 !-right-4 z-20"
            :src="imageUrl ? imageUrl : company.logo ? company.logo : ''"
            source="company"
            @on-profile-image="$emit('on-profile-image', $event)"
          />
          <div
            v-if="imageUrl"
            class="absolute bottom-0 right-3 cursor-pointer"
          >
            <div class="flex items-center m-2">
              <Icon
                v-if="edit"
                name="x"
                class="text-gray-900 bg-gray-400 hover:bg-gray-500 rounded-full w-8 h-8 flex items-center justify-center"
                @click="$emit('onCancel')"
              />
              <Icon
                v-if="edit"
                name="check"
                class="text-white bg-custom-orange-400 hover:bg-custom-orange-300 rounded-full w-8 h-8 flex items-center justify-center ml-1"
                @click="$emit('onSave')"
              />
            </div>
          </div>
        </div>
        <Title
          class="lg:mr-4 md:ml-12 flex flex-col"
          :class="[isEpp ? 'pt-14' : 'pt-16', isMobile ? '' : 'ml-6']"
          :size="minified ? 'text-lg leading-5' : 'text-xl lg:text-3xl'"
        >
          <div class="flex items-center">
            <span
              class="cursor-pointer font-semibold mr-2"
              @click="$emit('click', $event)"
            >{{
              isEpp ? company.market_name : company.name
            }}</span>
            <!-- <Icon
              v-if="company.is_follower && !isEpp"
              :v-tooltip="'You are following this company'"
              name="check-circle"
              solid
              class="text-custom-blue-700 text-xl mr-1"
            /> -->
          </div>
          <template v-if="isEpp">
            <div
              style="scrollbar-width: none; -ms-overflow-style: none"
              class="mt-2 flex flex-row mdx:space-x-4 md:space-x-2 space-x-4 text-sm max-w-52 xs:max-w-60 sm:max-w-none overflow-x-scroll"
            >
              <div class="flex flex-col">
                <span class="text-sm lg:text-base font-semibold">{{
                  company?.latest_exchange_market_cap?.companies_market_cap
                    ? '€' + company?.latest_exchange_market_cap?.companies_market_cap + 'M'
                    : '-'
                }}</span>
                <span
                  class="dark:text-custom-purple-200 text-custom-gray-400 font-medium whitespace-nowrap sm:whitespace-normal lg:text-sm text-xs"
                >Market cap</span>
              </div>
              <div class="flex flex-col">
                <span class="text-sm lg:text-base font-semibold">{{ company?.tradable_companies ?? 'N/A' }}</span>
                <span
                  class="dark:text-custom-purple-200 text-custom-gray-400 font-medium whitespace-nowrap sm:whitespace-normal lg:text-sm text-xs"
                >Traded cos.</span>
              </div>
              <div class="flex flex-col">
                <span class="text-sm lg:text-base font-semibold">{{ company?.total_companies ?? 'N/A' }}</span>
                <span
                  class="dark:text-custom-purple-200 text-custom-gray-400 font-medium whitespace-nowrap sm:whitespace-normal lg:text-sm text-xs"
                >Listed cos.</span>
              </div>
              <div

                class="flex flex-col"
              >
                <span class="text-sm lg:text-base font-semibold">{{ company?.total_premium_companies ?? '0' }}</span>
                <span
                  class="dark:text-custom-purple-200 text-custom-gray-400 font-medium whitespace-nowrap sm:whitespace-normal lg:text-sm text-xs"
                >Premium cos.</span>
              </div>
              <div class="flex flex-col">
                <span class="text-sm lg:text-base font-semibold">{{
                  company?.latest_exchange_market_cap?.companies_volume
                    ? '€' + company?.latest_exchange_market_cap?.companies_volume + 'M'
                    : '-'
                }}</span>
                <span
                  class="dark:text-custom-purple-200 text-custom-gray-400 font-medium whitespace-nowrap sm:whitespace-normal lg:text-sm text-xs"
                >Volume</span>
              </div>
            </div>
          </template>
          <template v-else-if="subtitle">
            <span
              v-if="subtitle"
              class="text-gray-500 dark:text-custom-purple-200"
              :class="[font, minified ? 'text-sm' : 'text-lg']"
            >{{ subtitle }}</span>
          </template>
          <template v-else>
            <div
              v-if="!minified || !subtitle"
              class="flex font-semibold items-center text-gray-500"
              :class="minified ? 'text-sm' : 'text-base'"
            >
              <div
                class="pr-2 lg:text-base md:text-sm text-xs dark:text-custom-purple-200 cursor-pointer"
                @click="
                  $route.name !== 'exploreCompanies' && !isEpp
                    ? $emit('go-to-explore-companies', company.pics.name)
                    : null
                "
              >
                {{ company.pics && company.pics.name ? company.pics.name : '' }}
              </div>
              <PremiumIcon
                :is-premium="!isEpp && company.is_premium"
                :v-tooltip="'This is a premium company'"
                medium
              />
            </div>
          </template>
        </Title>
      </div>
    </div>
  </div>
</template>

<script>
import SquareImageUploaderContainer from '@/modules/squareImageUploader'
export default {
  name: 'CompanyName',
  components: {
    SquareImageUploaderContainer
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    companyDetails: {
      type: Object,
      default: () => {}
    },
    subtitle: {
      type: String,
      default: null
    },
    minified: {
      type: Boolean,
      default: false
    },
    font: {
      type: String,
      default: 'font-semibold'
    },
    editMode: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: ''
    },
    isEpp: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'edit-logo', 'on-profile-image', 'onCancel', 'onSave', 'go-to-explore-companies', 'delete-logo']
}
</script>

<style lang="scss">
.dots-margin {
  margin-top: -15px;
}
</style>
