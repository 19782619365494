<template>
  <div v-if="!userType">
    <p
      v-if="showConfirmButton"
      class="text-right mt-8 text-xs"
    >
      <slot name="title">
        <p class="w-full text-center dark:text-primary-dark text-xl">
          {{ title }}
        </p>
      </slot>
    </p>

    <p class="text-sm dark:text-primary-offWhite text-center mt-2">
      {{ body }}
    </p>
    <slot
      name="message"
      class="text-sm dark:text-primary-dark text-center"
    />
    <p
      v-if="showConfirmButton"
      class="text-right mt-8 text-xs"
    >
      <BaseButton
        class="border border-custom-purple-500 ml-3 bg-custom-purple-500 text-white"
        @click="$emit('agree', type)"
      >
        <slot name="agree">
          {{ $t("Agree") }}
        </slot>
      </BaseButton>
      <BaseButton
        class="border dark:border-white border-custom-purple-500 ml-3"
        @click="$emit('toggle')"
      >
        {{ $t("Cancel") }}
      </BaseButton>
    </p>
    <p
      v-else
      class="text-right mt-8 text-xs"
    >
      <BaseButton
        class="border dark:border-white border-custom-purple-500 ml-3"
        @click="$emit('toggle')"
      >
        {{ $t("OK") }}
      </BaseButton>
    </p>
  </div>
  <div
    v-else
  >
    <div class="flex flex-wrap justify-between">
      <Logo
        black
        small
        class="cursor-pointer !w-32"
        @click="$emit('redirect-home', $event)"
      />
      <Icon
        name="x"
        class="text-4xl -mt-4 -mr-4 text-custom-purple-500 dark:text-custom-purple-100"
        @click="$emit('toggle')"
      />
    </div>
    <p
      class="mt-4 text-xs"
    >
      <slot name="title">
        <p class="text-lg  text-start dark:text-primary-offWhite text-custom-purple-500 font-semibold mt-2">
          Are you an investor or a company?
        </p>
      </slot>
    </p>

    <p class="text-sm text-start dark:text-primary-offWhite  font-medium mt-2">
      Please select the most suitable option to get the best experience.
    </p>
    <slot
      name="message"
      class="text-sm dark:text-primary-dark text-center"
    />
    <div
      class="w-full mt-8 flex flex-col text-center text-base font-medium"
    >
      <RouterLink :to="{ name: 'home' }">
        <div
          class="border flex justify-between px-4 w-full bg-neutral-200 dark:border-white py-3 border-neutral-400 rounded-md hover:bg-custom-purple-400 text-black hover:text-white"
          @click="$emit('clicked', )"
        >
          <span>
            {{ $t("I'm an investor") }}
          </span>
        
          <!-- <FontAwesomeIcon
            class="text-lg"
            icon="dollar-sign"
          /> -->
          <span class="font-extrabold text-lg">
            @          
          </span>
        </div>
      </RouterLink>
      <a :href="redirectUrl">
        <div
          class="border flex justify-between px-4 dark:border-white bg-neutral-200 border mt-4 py-3 w-full rounded-md border-neutral-400  hover:bg-custom-purple-400 text-black hover:text-white"
          @click="$emit('clicked')"
        >
          <span>
            {{ $t("I represent a company") }}
          </span>
          <FontAwesomeIcon
            class="text-lg"
            icon="building"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertModal',
  props: {
    title: { type: String, default: '' },
    body: { type: String, default: '' },
    type: { type: String, default: '' },
    redirectUrl: { type: String, default: '' },
    showConfirmButton: { type: Boolean, default: false },
    userType:{type: Boolean, default: false}
  },
emits: [
'agree',
'toggle',
'clicked'
]
}
</script>
