<template>
  <div
    v-if="showUserCongrats"
    class="flex flex-col lg:w-1/2 w-full z-60 py-8 sticky top-0 sm:static"
    :style="`background-image: url('svg/congrats-1.svg')`"
  >
    <div class="flex cursor-pointer bg-transparent justify-center lg:justify-start lg:ml-8">
      <Logo
        large
        class="hidden lg:block cursor-pointer"
        @click="$emit('redirect-home', $event)"
      />
      <Logo
        small
        class="block lg:hidden cursor-pointer -mb-2"
        @click="$emit('redirect-home', $event)"
      />
    </div>
  </div>
  <div
    v-else
    class="bg-custom-purple-500 dark:bg-custom-purple-800 dark:text-primary-dark lg:w-1/2 w-full z-60 py-8 sticky top-0 sm:static bg-image"
  >
    <div class="flex cursor-pointer bg-transparent justify-center lg:justify-start lg:ml-8">
      <Logo
        large
        class="hidden lg:block cursor-pointer"
        @click="$emit('redirect-home', $event)"
      />
      <Logo
        small
        class="block lg:hidden cursor-pointer -mb-2"
        @click="$emit('redirect-home', $event)"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignupTextSection',

  props: {
    user: {
      type: String,
      default: ''
    },
    showUserCongrats:{
        type: Boolean,
        default: false
    },
  },
  emits: [
    'redirect-home'
  ]
}
</script>
<style scoped>
@media (min-width: 1024px) {
  .bg-image {
    background-position: center;
    background-image: url('~@/assets/img/world.jpg')
  }
}
</style>