<template>
  <div
    class="logo font-header relative font-bold flex flex-col text-center items-center text-custom-light-header dark:text-custom-dark-header"
    @click="$emit('click', $event)"
  >
    <img
      :class="[small ? 'w-40 mb-2' : 'w-40', large? 'w-60' : 'w-40', 'relative']"
      :src="black ? '/logo_black.png' : '/logo.svg'"
    >
    <span class="bg-green-500 text-white px-1 rounded-sm text-2xs absolute -top-3 -right-8">BETA</span>
  </div>
</template>

<script>

export default {
  name: 'Logo',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    black:{
      type: Boolean,
      default: false
    }
  },
  emits: [
    'click'
  ]
}
</script>
