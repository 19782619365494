<template>
  <!-- <div
    id="stickyTopMenu"
    ref="stickyTopMenu"
    class=" w-full shadow-sm dark:shadow-sm-dark z-70 sticky top-0 dark:border-custom-purple-500"
    :class="[secondary ? 'bg-custom-purple-500 text-white shadow-none' : 'bg-white dark:bg-custom-purple-800', isApple && addPadding? 'pt-4': '']"
  >
    <slot />
  </div> -->

  <div
    id="stickyTopMenu"
    ref="stickyTopMenu"
    class=" w-full shadow-sm dark:shadow-sm-dark z-60 sticky top-16 dark:border-custom-purple-500 fade-in"
    :class="[secondary ? 'bg-custom-purple-500 text-white shadow-none' : 'bg-white dark:bg-custom-purple-800', isApple && addPadding ? 'pt-6' : isMobile && addPadding ? 'pt-4' : '', show? 'block ' : 'hidden ', isKycPending ? 'sm:top-6' : 'sm:top-0']"
  >
    <slot />
  </div>
</template>

<script>
import { isIphone } from '@/js/helpers/mobile'
import { Capacitor } from '@capacitor/core'
import { isMobile } from '@/js/helpers/mobile'
import { useUsersStore } from '../stores/users';

export default {
  name: 'HorizontalSubMenu',
  props: {
    secondary: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const usersStore = useUsersStore()
    return { usersStore }
  },
  data () {
    return {
      addPadding: false,
      scrollPosition: null,
      show: false
    }
  },
  computed: {
    isApple () {
      return Capacitor.getPlatform() === 'ios' || isIphone()
    },
    isKycPending() {
      return this.usersStore?.isLoggedin && this.usersStore?.user?.profile?.is_verified && this.usersStore?.user?.is_kyc_pending
    },
	isMobile () {
      return isMobile()
    },
  },
  mounted () {
    window.addEventListener('scroll', this.scrollAddPadding, false)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.scrollAddPadding, false)
  },
  methods: {
    scrollAddPadding () {
      const self = this
      // const element = self.$refs.stickyTopMenu
      // const elementTop = element.getBoundingClientRect().top

      window.addEventListener('scroll', () => {
        if (window.scrollY >= 600) {
          self.addPadding = true
          self.show = true
        } else {
          self.addPadding = false
          self.show = false
        }
      })
    }
  }

}
</script>
<style scoped>
/* Define the fade-in animation using @keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



/* Apply the animation when the element is visible */
.fade-in {
  animation: fadeIn 0.2s ease-in-out;
}

</style>






