<template>
  <div v-if="notification?.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div
          @click="$parent.redirectTransaction({transactionId: notification?.notifiable?.dam_wallet_transaction?.id, notificationId: notification.id})"
        > 
          <div class="text-sm pr-4">
            Congratulations {{ notification?.notifiable?.stock_user?.full_name }}, your deposit of {{ $parent.abbreviateDecimal(notification?.notifiable?.dam_wallet_transaction?.amount) }} {{ notification?.notifiable?.dam_wallet_transaction?.currency }} has arrived! Click here to view your account and start trading.
          </div>
          <div class="text-xs font-bold mt-1 mr-6">
            {{ time }}
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationFundsDepositSuccess',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
],
}
</script>
