<template>
  <div :class="imagePath ? (isMap ? 'pb-6' : 'pb-44') : 'pb-4'">
    <div
      class="w-full object-cover absolute"
      :class="
        imagePath
          ? isMap
            ? 'h-24 rounded-t-lg'
            : 'h-60 rounded-b-lg'
          : 'h-20 ' + $route.name === 'companyPage'
            ? 'bg-gradient-to-b from-white to-custom-offWhite-200 opacity-[.16]'
            : 'bg-white'
      "
    >
      <img
        v-if="imagePath"
        :src="imagePath"
        class="h-full w-full object-cover"
        :class="isMap ? 'rounded-t-lg' : 'rounded-b-lg'"
      >
    </div>

    <EditButtonCircle
      v-if="editMode"
      :edit="edit"
      class="absolute -right-4 top-2 z-20"
      @click="edit = !edit"
    />
    <div
      v-if="theCompany?.banner && edit"
      class="absolute right-10 bottom-32 z-20 rounded-full w-8 h-8 flex justify-center items-center p-1 cursor-pointer"
      :class="'bg-custom-orange-400 hover:bg-custom-orange-300 text-white'"
      @click="deleteBanner"
    >
      <Icon
        :name="'trash'"
        class="text-lg"
        solid
      />
    </div>
    <SquareImageUploaderContainer
      v-if="edit"
      class="absolute z-20"
      :class="imagePath ? '-right-4 bottom-32' : '-right-4 -top-4 mb-8'"
      :src="imagePath"
      source="company"
      @on-profile-image="onProfileImage"
    />
    <div
      v-if="imageUrl"
      class="absolute bottom-32 right-10 cursor-pointer"
    >
      <div class="flex items-center z-20">
        <Icon
          v-if="edit"
          name="x"
          class="text-gray-900 bg-gray-400 hover:bg-gray-500 rounded-full w-8 h-8 flex items-center justify-center shadow-lg"
          @click="onCancel"
        />
        <Icon
          v-if="edit"
          name="check"
          class="text-white bg-custom-orange-400 hover:bg-custom-orange-300 rounded-full w-8 h-8 flex items-center justify-center shadow-lg ml-1"
          @click="onSave"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { v4 as uuidv4 } from 'uuid'
import { uploadFiles } from '@/js/helpers/aws'
import { useCompaniesStore } from '../../stores/companies'
import { useExchangesStore } from '../../stores/exchanges'
import SquareImageUploaderContainer from '@/modules/squareImageUploader'

export default {
  name: 'CompanyBannerModule',
  components: {
    SquareImageUploaderContainer
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    isEpp: {
      type: Boolean,
      default: false
    },
    isMap: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const companiesStore = useCompaniesStore()
    const exchangesStore = useExchangesStore()

    return { companiesStore, exchangesStore }
  },
  data() {
    return {
      edit: false,
      profileImage: null,
      imageUrl: null,
      path: 'media/banner/'
    }
  },
  computed: {
    imagePath() {
      return this.imageUrl ? this.imageUrl : this.theCompany?.banner ? this.theCompany?.banner : ''
    },
    style() {
      const style = {
        width: `${this.size}px`,
        height: `${this.size}px`
      }

      return style
    },
    selectedCompanyDetails() {
      return this.companiesStore.companyDetails
    },
    theCompany() {
      return this.company ?? this.companiesStore.selectedCompany
    },
    theCompanyDetails() {
      if (this.companyDetails) {
        return this.companyDetails
      }
      return this.selectedCompanyDetails
    }
  },
  methods: {
    onProfileImage(payload) {
      this.profileImage = payload.profileImage
      this.imageUrl = payload.imageUrl
      // this.onSave()
    },
    deleteBanner() {
      if (this.theCompany?.banner) {
        this.isEpp ? this.exchangesStore.deleteExchangeBanner(this.theCompany?.slug_url) : this.companiesStore.deleteCompanyBanner(this.theCompany?.slug_url)
      }
      this.onCancel()
    },
    onCancel() {
      this.imageUrl = null
      this.profileImage = null
      this.edit = false
    },
    async onSave() {
      let update = {}
      if (!this.profileImage) {
        this.onCancel()
        return
      }
      if (this.profileImage) {
        const uuid = uuidv4()
        const filename = this.path + uuid + '/' + this.profileImage.name.split(' ').join('_')
        await uploadFiles([
          {
            filename,
            type: 'image',
            uuid,
            data: this.profileImage,
            exist: false
          }
        ])
        update.filename = filename
      }
      if (update) {
        this.isEpp ? (update.type = 'banner') : (update.slugUrl = this.theCompany.slug_url)
        this.isEpp ? await this.exchangesStore.uploadLogo(update) : await this.companiesStore.uploadBanner(update)
        this.onCancel()
      }
    }
  }
}
</script>

  
