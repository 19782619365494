import { get, post, put, destroy} from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const fetch = async () => get(`${url}/exchanges`)
const fetchExchanges = async () => get(`${url}/exchanges`)
const deleteExchangeBanner = (slugUrl) => destroy(`${url}/exchange/${slugUrl}/delete-banner`)
const deleteExchangeLogo = (slugUrl) => destroy(`${url}/exchange/${slugUrl}/delete-logo`)
const fetchExchange = async (slugUrl) => get(`${url}/exchange/${slugUrl}`)
const fetchExchangeNews = async (slugUrl) => get(`${url}/exchange/${slugUrl}/news`)
const fetchExchangeBestCompanies = async (slugUrl) => get(`${url}/exchange/${slugUrl}/best-companies`)
const fetchExchangeCurrentYearCompanies = async (slugUrl) => get(`${url}/exchange/${slugUrl}/current-year-companies`)
const fetchExchangeMarketCap = async (slugUrl) => get(`${url}/exchange/${slugUrl}/exchange-marketcap`)
const uploadLogo = async (slugUrl, data) => post(`${url}/exchange/${slugUrl}/add-image`, data)
const saveNewAdvisor = async (id, data) => post(`${url}/exchange/${id}/add-corporate-advisor`, data)
const updateAdvisor = async (id, advisorId, data) => put(`${url}/exchange/${id}/update-corporate-advisor/${advisorId}`, data)
const deleteAdvisor = async (id, advisorId) => destroy(`${url}/exchange/${id}/delete-corporate-advisor/${advisorId}`)
const saveNewMember = async (id, data) => post(`${url}/exchange/${id}/add-market-member`, data)
const updateMember = async (id, memberId, data) => put(`${url}/exchange/${id}/update-market-member/${memberId}`, data)
const deleteMember = async (id, memberId) => destroy(`${url}/exchange/${id}/delete-market-member/${memberId}`)
const saveNewManagement = async (id, data) => post(`${url}/exchange/${id}/add-management`, data)
const updateManagement = async (id, managementId, data) => put(`${url}/exchange/${id}/update-management/${managementId}`, data)
const deleteManagement = async (id, managementId) => destroy(`${url}/exchange/${id}/delete-management/${managementId}`)
const newDocumentListFile = async (id, data) => post(`${url}/exchange/${id}/upload-document`, data)
const updateDocumentListFile =  async(id, documentId, data)=> put(`${url}/exchange/${id}/update-document/${documentId}`, data)
const deleteDocumentListFile = async (id, documentId) => destroy(`${url}/exchange/${id}/delete-document/${documentId}`)
const updateExchangeFees = async (id, data) => post(`${url}/exchange/${id}/update-fees`, data)
const saveNewSMEService = async (id, data) => post(`${url}/exchange/${id}/add-sme-service`, data)
const updateSMEService = async (id, serviceId, data) => put(`${url}/exchange/${id}/update-sme-service/${serviceId}`, data)
const deleteSMEService = async (id, serviceId) => destroy(`${url}/exchange/${id}/delete-sme-service/${serviceId}`)
const updateContactInfo = async (id, data) => post(`${url}/exchange/${id}/update-contact`, data)
const updateLocation = async (id, data) => post(`${url}/exchange/${id}/update-location`, data)
const deleteLocation = async (id, locationId, data) => destroy(`${url}/exchange/${id}/delete-location/${locationId}`, data)
const saveNewSegment = async (id, data) => post(`${url}/exchange/${id}/add-market-segment`, data)
const updateSegment = async (id, segmentId, data) => put(`${url}/exchange/${id}/update-market-segment/${segmentId}`, data)
const deleteSegment = async (id, segmentId) => destroy(`${url}/exchange/${id}/delete-market-segment/${segmentId}`)
const addVideo = async (id, data) => post(`${url}/exchange/${id}/add-video`, data)
const editVideo = async (id, videoId, data) => put(`${url}/exchange/${id}/update-video/${videoId}`, data)
const deleteVideo = async (id, videoId) => destroy(`${url}/exchange/${id}/delete-video/${videoId}`)
const followExchange = async(id) => post(`${url}/exchange/${id}/follow-exchange`)
const unfollowExchange = async(id) => post(`${url}/exchange/${id}/unfollow-exchange`)
const newRuleDocumentListFile = async (id, data) => post(`${url}/exchange/${id}/upload-rules-document`, data)
const updateRuleDocumentListFile =  async(id, documentId, data)=> put(`${url}/exchange/${id}/update-rules-document/${documentId}`, data)
const deleteRuleDocumentListFile = async (id, documentId) => destroy(`${url}/exchange/${id}/delete-rules-document/${documentId}`)
const share = async(id) => post(`${url}/exchange/${id}/share`)
const fetchExchangeCard = async (slugUrl) => get(`${url}/exchange/${slugUrl}/map-card`)
const fetchExchangeCompanies = async (slugUrl, data) => get(`${url}/exchange/${slugUrl}/exchange-companies`, data)
const scroll = async (link, data) => get(`${link}`, data)
const fetchFollowingExchanges = async () => get(`${url}/exchanges/user-follow-list`)

export default {
  fetch,
  deleteExchangeLogo,
  deleteExchangeBanner,
  fetchExchange,
  fetchExchanges,
  fetchExchangeNews,
  fetchExchangeBestCompanies,
  fetchExchangeCurrentYearCompanies,
  fetchExchangeMarketCap,
  uploadLogo,
  saveNewAdvisor,
  updateAdvisor,
  deleteAdvisor,
  saveNewMember,
  updateMember,
  deleteMember,
  saveNewManagement,
  updateManagement,
  deleteManagement,
  newDocumentListFile,
  updateDocumentListFile,
  deleteDocumentListFile,
  updateExchangeFees,
  saveNewSMEService,
  updateSMEService,
  deleteSMEService,
  updateContactInfo,
  updateLocation,
  deleteLocation,
  saveNewSegment,
  updateSegment,
  deleteSegment,
  addVideo,
  editVideo,
  deleteVideo,
  followExchange,
  unfollowExchange,
  newRuleDocumentListFile,
  updateRuleDocumentListFile,
  deleteRuleDocumentListFile,
  share,
  fetchExchangeCard,
  fetchExchangeCompanies,
  scroll,
  fetchFollowingExchanges
}
