<template>
  <div class="flex lg:flex-row flex-col h-full w-full">
    <SignupText
      class="lg:text-white"
      :user="userType"
      :show-user-congrats="showUserCongrats"
      @redirect-home="redirectHome"
    />
    <div
      :class="!isVerified ? 'p-4' : ''"
      class="lg:w-1/2 lg:py-24 w-full h-full flex flex-col items-center bg-white"
    >
      <div
        v-if="usersStore.loadingRegistration"
        class="modal fixed left-0 top-0 inset-0 flex items-center z-60"
      >
        <div class="absolute w-full h-full bg-custom-purple-800 z-10 opacity-75" />
        <div class="flex w-full h-full z-60 items-center justify-center">
          <CircleLoader :white="true" />
        </div>
      </div>
      <CreatePasswordContainer
        v-if="usersStore.showCreatePassword"
        :has-upper-or-lower-case="passwordHasUppercase && passwordHasLowercase"
        :is-length-valid="passwordIsLengthValid"
        :has-numbers="passwordHasNumbers"
        :has-special-chars="passwordHasSpecialChars"
        :show-password="showPassword"
        :password="password"
        @back-to-signup-form="usersStore.setShowCreatePassword(false)"
        @password="setPassword"
        @on-submit="addUser"
        @toggle-show-password="showPassword = !showPassword"
      />
      <CongratsUserContainer
        v-else-if="showUserCongrats"
        :is-mobile="isMobile"
        :step-loading="stepLoading"
        @redirect-to-kyc="redirectKyc"
      />
      <EmailVerificationContainer v-else-if="showEmailVerification" />
      <OnboardingContainer v-else-if="showUserVerification" />


      <SignupForm
        v-else
        :check-availability-error="usersStore.checkAvailabilityError"
        :username="username"
        :email="email"
        :terms="terms"
        :redirect-url="redirectUrl"
        @terms="terms = !terms"
        @show-terms="showTerms = !showTerms"
        @email="setEmail"
        @username="setUsername"
        @on-submit="checkAvailability"
        @reset-error="usersStore.setCheckAvailabilityError('')"
      />
    </div>

    <ModalContainer
      v-if="showTerms"
      full-width
      :show="showTerms"
      @toggle="showTerms = !showTerms"
      @on-back-button="showTerms = !showTerms"
    >
      <Terms />
      <NeutralButton
        class="w-full text-md h-12 focus:outline-none"
        @click="showTerms = !showTerms"
      >
        {{ $t('Close') }}
      </NeutralButton>
    </ModalContainer>
  </div>
</template>

<script>
import SignupForm from './_components/SignupForm'
import SignupText from './_components/SignupText'
import CreatePasswordContainer from './_components/CreatePassword'
import CongratsUserContainer from './_components/CongratsUser'
import { useUsersStore } from '../../stores/users'
import { useOnboardingsStore } from '@/stores/onboarding'
import EmailVerificationContainer from '@/modules/emailVerificationHub'
import OnboardingContainer from '@/modules/onboardingNew'
import ModalContainer from '@/modules/modal'
import Terms from '@/views/Terms'
import { isMobile } from '@/js/helpers/mobile'
export default {
  name: 'SignupModule',
  components: {
    SignupForm,
    SignupText,
    EmailVerificationContainer,
    OnboardingContainer,
    CreatePasswordContainer,
    CongratsUserContainer,
    ModalContainer,
    Terms

  },
  setup () {
    const usersStore = useUsersStore()
    const onboardingsStore = useOnboardingsStore()
    return { usersStore, onboardingsStore }
  },
  data () {
    return {
      username: '',
      email: '',
      password: '',
      terms: false,
      showTerms: false,
      stepLoading: false,
      showCreatePassword: false,
      showPassword: false,

    }
  },
  computed: {
    redirectUrl() {
      return process.env.VUE_APP_HS_URL + `/terms-of-service-website`
    },
    alreadyRegistered () {
      return this.usersStore.alreadyRegistered
    },
    loadingRegistration () {
      return this.usersStore.loadingRegistration
    },
    countries () {
      return this.countriesStore.countries
    },
    user () {
      return this.usersStore.user
    },
    countryList () {
      return this.countries.filter(country => country.name.toLowerCase().startsWith(this.countrySearch.toLowerCase())).map(country => {
        return {
          id: country?.id,
          code: country?.code,
          name: country?.name
        }
      })
    },
    showEmailVerification () {
      return this.user && this.user?.profile_type && this.user?.profile_type !== 'App\\Models\\GuestUser' && !this.user?.email_verified_at
    },
    showUserVerification () {
      return (this.user && this.user?.profile && this.user?.profile_type !== 'App\\Models\\GuestUser' && !this.user?.profile.is_verified)
    },
    showUserCongrats () {
      return (this.user && this.user?.profile && this.user?.profile_type !== 'App\\Models\\GuestUser' && this.user?.profile.is_verified)
    },
    passwordIsLengthValid () {
      return this.password.length >= 8;
    },
    passwordHasUppercase () {
      return /[A-Z]/.test(this.password);
    },
    passwordHasLowercase () {
      return /[a-z]/.test(this.password);
    },
    passwordHasNumbers () {
      return /[0-9]/.test(this.password);
    },
    passwordHasSpecialChars () {
      return /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },
    isVerified () {
      return this.user && this.user?.profile && this.user?.profile.is_verified
    },
    isMobile () {
      return isMobile()
    },
    isInvestor() {
      return this.user && this.user?.profile && this.user?.is_investor
    },
  },
  mounted() {
    if (this.isMobile) {
      const chatBox = document.getElementById('hubspot-messages-iframe-container')
      if (chatBox) {
        chatBox.id ='hideHubspot'
      }
    }
  },
  beforeMount(){
    if(this.isVerified && this.isInvestor){
      this.redirectHome()
    }
  },
  beforeUnmount() {
    const chatBox = document.getElementById('hideHubspot')
    if (chatBox) {
      chatBox.id = 'hubspot-messages-iframe-container'
    }
  },
  methods: {
    setUsername (event) {
      this.username = event.target.value
      this.resetAlreadyRegistered()
    },
    setEmail (event) {
      this.email = event.target.value
      this.resetAlreadyRegistered()
    },
    setPassword (event) {
      this.password = event.target.value
      this.resetAlreadyRegistered()
    },
    resetAlreadyRegistered () {
      if (this.alreadyRegistered) {
        this.usersStore.resetRegisterMessage()
      }
    },
    async addUser () {
      await this.usersStore.register({
        username: this.username,
        email: this.email,
        password: this.password
      })
    },
    redirectHome () {
      this.$router.push('/')
    },
    async checkAvailability () {
      await this.usersStore.checkAvailability({
        username: this.username,
        email: this.email
      })
    },
    async redirectKyc () {
      try {
        this.stepLoading = true
        await this.onboardingsStore?.setKycPayload('step', 1)
        await this.onboardingsStore.storeUserDetails()
        this.$router.push({ name: 'kyc' })
        this.stepLoading = false
      } catch (e) {
        console.error(e)
        this.stepLoading = false
      }

    }
  }
}
</script>

<style>
#hideHubspot{
  display: none !important;
 }
</style>