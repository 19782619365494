import { get, post, put, upload, destroy } from '../helpers/axiosHelper'
const baseUrl = `${process.env.VUE_APP_API_URL}`
const url = `${process.env.VUE_APP_API_URL}/companies`
const list = async (data) => get(`${url}`, data)
const premiumCompanies = async () => get(`${url}/premium-companies`)
const instrumentsList = async (data) => post(`${url}/instruments-list`, data)
const fetchSectorAndValuation = async () => get(`${url}/sectors-and-valuation`)
const brokerList = async (data) => get(`${process.env.VUE_APP_API_URL}/brokers`, data)
const companyDataList = async (id, data) => get(`${url}/${id}/data-list`, data)
const one = async (id) => get(`${url}/${id}`)
const boardMemberList = async (id) => get(`${url}/${id}/board-members`)
const ownersList = async (id) => get(`${url}/${id}/ownership-list`)
const triggerList = async (id) => get(`${url}/${id}/triggers`)
const contactList = async (id) => get(`${url}/${id}/contacts`)
const documentList = async (id) => get(`${url}/${id}/documents`)
const addBoardMember = async (id, data) => post(`${url}/${id}/board-members`, data)
const addOwner = async (id, data) => post(`${url}/${id}/ownership-list`, data)
const addContact = async (id, data) => post(`${url}/${id}/contacts`, data)
const addTrigger = async (id, data) => post(`${url}/${id}/triggers`, data)
const addDocument = async (id, data) => post(`${url}/${id}/documents`, data)
const updateDocument = async (id, documentId, data) => post(`${url}/${id}/documents/update-document/${documentId}`, data)
const deleteDocument = async (id, documentId) => post(`${url}/${id}/documents/delete-document/${documentId}`)
const assetList = async (id) => get(`${url}/${id}/assets`)
const dataList = async (id) => get(`${url}/${id}/data`)
const deleteCompanyBanner = (id) => destroy(`${url}/${id}/delete-banner`)
const deleteCompanyLogo = (id) => destroy(`${url}/${id}/delete-logo`)
const fetchCompanyUsers = async (id) => get(`${url}/${id}/users`)
const addAsset = async (id, data) => post(`${url}/${id}/assets`, data)
const saveSocialTags = async (id, data) => put(`${url}/${id}/social-tags`, data)
const saveNewManagement = async (id, data) => post(`${url}/${id}/management`, data)
const updateManagement = async (id, managementId, data) => put(`${url}/${id}/management/${managementId}/update`, data)
const deleteManagement = async (id, managementId) => post(`${url}/${id}/management/${managementId}/delete`)
const saveNewReasonToInvest = async (id, data) => post(`${url}/${id}/reason-to-invest`, data)
const updateReasonToInvestDescription = async (id, data) => put(`${url}/${id}/reason-to-invest/update-reason-to-invest-description`, data)
const updateUSPDescription = async (id, data) => put(`${url}/${id}/business-details/usp/update-usp-description`, data)
const updateReasonToInvest = async (id, reasonToInvestId, data) => put(`${url}/${id}/reason-to-invest/${reasonToInvestId}`, data)
const deleteReasonToInvest = async (id, reasonToInvestId) => destroy(`${url}/${id}/reason-to-invest/${reasonToInvestId}`)
const saveNewBusinessDetail = async (id, data) => post(`${url}/${id}/business-details`, data)
const saveNewBusinessGrowthDetail = async (id, data) => post(`${url}/${id}/business-details/growth`, data)
const saveNewBusinessCSRDetail = async (id, data) => post(`${url}/${id}/business-details/csr`, data)
const fetchBusinessCSRDetail = async (id) => get(`${url}/${id}/business-details/csr`)
const fetchBusinessAnnualReportDetail = async (id) => get(`${url}/${id}/business-details/annual-report`)
const fetchBusinessGrowthDetail = async (id) => get(`${url}/${id}/business-details/growth`)
const updateBusinessDetail = async (id, businessDetailId, data) => put(`${url}/${id}/business-details/${businessDetailId}`, data)
const updateBusinessDetailGrowth = async (id, businessDetailId, data) => put(`${url}/${id}/business-details/growth/${businessDetailId}`, data)
const updateBusinessDetailCSR = async (id, businessDetailId, data) => put(`${url}/${id}/business-details/csr/${businessDetailId}`, data)
const saveNewBusinessRndDetail = async (id, data) => post(`${url}/${id}/business-details/rnd`, data)
const updateBusinessDetailAnnualReport = async (id, businessDetailId, data) => put(`${url}/${id}/business-details/annual-report/${businessDetailId}`, data)
const fetchBusinessRndDetail = async (id) => get(`${url}/${id}/business-details/rnd`)
const saveNewBusinessAnnualReportDetail = async (id, data) => post(`${url}/${id}/business-details/annual-report`, data)
const updateBusinessRndDetail = async (id, businessDetailId, data) => put(`${url}/${id}/business-details/rnd/${businessDetailId}`, data)
const deleteBusinessAnnualDetail = async (id, businessDetailId) => destroy(`${url}/${id}/business-details/${businessDetailId}/annual-report`)
const deleteBusinessRndDetail = async (id, businessDetailId) => destroy(`${url}/${id}/business-details/${businessDetailId}/rnd`)
const deleteBusinessCSRDetail = async (id, businessDetailId) => destroy(`${url}/${id}/business-details/${businessDetailId}/csr`)
const deleteBusinessDetail = async (id, businessDetailId) => destroy(`${url}/${id}/business-details/${businessDetailId}`)
const deleteBusinessGrowthDetail = async (id, businessDetailId) => destroy(`${url}/${id}/business-details/${businessDetailId}/growth`)
const updateContactInfo = async (id, data) => put(`${url}/${id}/contact-info`, data)
const updateLocations = async (id, data) => put(`${url}/${id}/locations`, data)
const deleteLocation = async (id, locationId) => post(`${url}/${id}/locations/${locationId}/delete`)
const toggleWidget = async (id, data) => put(`${url}/${id}/widgets`, data)
const toggleTrialPeriod = async (companySN) => put(`${url}/${companySN}/trial-period`)
const toggleCompanyStrategy = async (companySN, strategyId) => put(`${url}/${companySN}/strategies/${strategyId}`)
const updateCompanySocial = async (companySN, social) => put(`${url}/${companySN}/social`, social)
const deleteCompanySocial = async (companySN, socialLinkId) => destroy(`${url}/${companySN}/social/${socialLinkId}`)
const newDocumentListFile = async (companySN, data) => post(`${url}/${companySN}/document-list`, data)
const deleteDocumentListFile = async (companySN, id) => destroy(`${url}/${companySN}/document-list/${id}`)
const updateDocumentListFile = async (companySN, data) => put(`${url}/${companySN}/document-list/${data.id}`, data)
const addUsersToCompany = async (id, data) => post(`${url}/${id}/add-users-role`, data)
const removeUsersRole = async (id, data) => post(`${url}/${id}/remove-users-role`, data)
const uploadLogo = async (companySN, data) => post(`${url}/${companySN}/logo`, data)
const uploadBanner = async (companySN, data) => post(`${url}/${companySN}/set-banner`, data)
const setLogoBackground = async (companySN, data) => post(`${url}/${companySN}/logo-background`, data)

const addEvent = async (id, data) => post(`${url}/${id}/event`, data)
const eventList = async (id) => get(`${url}/${id}/event`)
const eventDelete = async (companyId, eventId) => post(`${url}/${companyId}/event/${eventId}/delete`)
const eventDeleteRequest = async (companyId, eventId) => post(`${url}/${companyId}/event/${eventId}/delete-request`)
const approveEvent = async (companyId, requestId, eventId) => post(`${url}/${companyId}/event/${eventId}/delete-request/${requestId}/approve`)
const rejectEvent = async (companyId, requestId, eventId) => post(`${url}/${companyId}/event/${eventId}/delete-request/${requestId}/reject`)
const memberList = async (id) => get(`${url}/${id}/members`)
const follow = async (id) => post(`${url}/${id}/follow`)
const followMultiple = async (data) => post(`${url}/follow-multiple`, data)
const store = async (data, companyId) => upload(`${url}/${companyId}/media`, data)
const updateMedia = async (data, companyId, mediaId) => upload(`${url}/${companyId}/media/${mediaId}/update`, data)
const agree = async (companyId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/agree`)
const disagree = async (companyId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/disagree`)
const userRequestList = async (id) => get(`${url}/${id}/user-requests`)
const listMedia = async (companyId, filter) => get(`${url}/${companyId}/media`, filter)
const fetchMedia = async (id) => get(`${process.env.VUE_APP_API_URL}/media/${id}/fetch`)
const scrollMedia = async (link, data) => get(`${link}`, data)
const contactDelete = async (companyId, contactId) => post(`${url}/${companyId}/contacts/${contactId}/delete`)
const contactDeleteRequest = async (companyId, contactId) => post(`${url}/${companyId}/contacts/${contactId}/delete-request`)
const approveContact = async (companyId, requestId, contactId) => post(`${url}/${companyId}/contacts/${contactId}/delete-request/${requestId}/approve`)
const rejectContact = async (companyId, requestId, contactId) => post(`${url}/${companyId}/contacts/${contactId}/delete-request/${requestId}/reject`)
const mediaLike = async (mediaId, companyId, data) => post(`${url}/${companyId}/media/${mediaId}/likes`, data)
const fetchSubComment = async (id) => get(`${process.env.VUE_APP_API_URL}/comments/${id}/comments`)
const addSubComment = async (companyId, commentId, data) => post(`${url}/${companyId}/comments/${commentId}/comments`, data)
const commentLike = async (id, data) => post(`${url}/comments/${id}/likes`, data)
const shareWithGeneral = async (mediaId, data) => post(`${process.env.VUE_APP_API_URL}/media/${mediaId}/share`, data)
const shareWithCompany = async (mediaId, companyId, data) => post(`${process.env.VUE_APP_API_URL}/companies/${companyId}/media/${mediaId}/share`, data)
const triggerDeleteRequest = async (companyId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/delete-request`)
const triggerDelete = async (companyId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/delete`)
// const reportTrigger = async (companyId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/report`)
const assetDelete = async (companyId, assetId) => post(`${url}/${companyId}/assets/${assetId}/delete`)
const assetDeleteRequest = async (companyId, assetId) => post(`${url}/${companyId}/assets/${assetId}/delete-request`)
const boardMemberDelete = async (companyId, boardMemberId) => post(`${url}/${companyId}/board-members/${boardMemberId}/delete`)
const boardMemberDeleteRequest = async (companyId, boardMemberId) => post(`${url}/${companyId}/board-members/${boardMemberId}/delete-request`)
const ownerDelete = async (companyId, ownerId) => post(`${url}/${companyId}/ownership-list/${ownerId}/delete`)
const ownerDeleteRequest = async (companyId, ownerId) => post(`${url}/${companyId}/ownership-list/${ownerId}/delete-request`)
const approveTrigger = async (companyId, requestId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/delete-request/${requestId}/approve`)
const rejectTrigger = async (companyId, requestId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/delete-request/${requestId}/reject`)
const approveAsset = async (companyId, requestId, assetId) => post(`${url}/${companyId}/assets/${assetId}/delete-request/${requestId}/approve`)
const rejectAsset = async (companyId, requestId, assetId) => post(`${url}/${companyId}/assets/${assetId}/delete-request/${requestId}/reject`)
const approveBoardMember = async (companyId, requestId, boardMemberId) => post(`${url}/${companyId}/board-members/${boardMemberId}/delete-request/${requestId}/approve`)
const rejectBoardMember = async (companyId, requestId, boardMemberId) => post(`${url}/${companyId}/board-members/${boardMemberId}/delete-request/${requestId}/reject`)
const approveOwner = async (companyId, requestId, ownerId) => post(`${url}/${companyId}/ownership-list/${ownerId}/delete-request/${requestId}/approve`)
const rejectOwner = async (companyId, requestId, ownerId) => post(`${url}/${companyId}/ownership-list/${ownerId}/delete-request/${requestId}/reject`)
const shareTriggerWithAll = async (companyId, triggerId, data) => post(`${url}/${companyId}/triggers/${triggerId}/followers/share`, data)
const shareTriggerWithCompany = async (companyId, triggerId, data) => post(`${url}/${companyId}/triggers/${triggerId}/share`, data)
const savePressRelease = async (companyId, pressReleaseId) => post(`${url}/${companyId}/press-releases/${pressReleaseId}/save`)
const saveTrigger = async (companyId, triggerId) => post(`${url}/${companyId}/triggers/${triggerId}/save`)
const saveAsset = async (companyId, assetId) => post(`${url}/${companyId}/assets/${assetId}/save`)
const saveDocument = async (companyId, documentId) => post(`${url}/${companyId}/documents/${documentId}/save`)
const scroll = async (link, data) => get(`${link}`, data)
const reportIncident = async (data) => post(`${process.env.VUE_APP_API_URL}/incidents/report-company-incident`, data)
const scrollInstruments = async (link, data) => post(`${link}`, data)
const addCompanyDetails = async (id, data) => post(`${url}/${id}/details`, data)
const fetchCompanyDetails = async (id) => get(`${url}/${id}/details`)
const addCompanyMarketGoals = async (id, data) => post(`${url}/${id}/marketGoals`, data)
const fetchCompanyMarketGoals = async (id) => get(`${url}/${id}/marketGoals`)
const addCompanyCompetitors = async (companyId, competitorId, data) => post(`${url}/${companyId}/${competitorId}/competitors`, data)
const addUnknownCompanyCompetitors = async (companyId, data) => post(`${url}/${companyId}/competitors`, data)
const fetchCompanyCompetitors = async (id) => get(`${url}/${id}/competitors`)
const addComment = async (mediaId, companyId, data) => upload(`${url}/${companyId}/media/${mediaId}/comments`, data)
const commentList = async (mediaId, companyId) => get(`${url}/${companyId}/media/${mediaId}/comments`)
const getBrokerStats = async (companyId, data) => get(`${url}/${companyId}/brokerstats`, data)
const getBrokerStatsByBroker = async (data) => get(`${process.env.VUE_APP_API_URL}/brokerstats`, data)
const fetchCompanyDiscussions = async (id, limit) => get(`${process.env.VUE_APP_API_URL}/chats/${id}/messages?limit=${limit}`)
const statistics = async (id) => get(`${url}/${id}/statistics`)
const fetchFinancials = async (id, currency, periodCode) => get(`${url}/${id}/financials`, { currency, period: periodCode })
const fetchMarketCap = async (id, currency) => get(`${url}/${id}/market-cap`, { currency })
const saveBurnrate = async (companyId, payload) => post(`${url}/${companyId}/burnrates`, payload)
const saveCash = async (companyId, payload) => post(`${url}/${companyId}/cash`, payload)
const deleteMedia = async (mediaId, companyId) => post(`${url}/${companyId}/media/${mediaId}/delete`)
const reportMedia = async (mediaId, companyId) => post(`${url}/${companyId}/media/${mediaId}/report`)
const mediaDeleteRequest = async (mediaId, companyId) => post(`${url}/${companyId}/media/${mediaId}/delete-rquest`)
const approveDeleteMedia = async (mediaId, requestId, companyId) => post(`${url}/${companyId}/media/${mediaId}/delete-request/${requestId}/approve`)
const rejectDeleteMedia = async (mediaId, requestId, companyId) => post(`${url}/${companyId}/media/${mediaId}/delete-request/${requestId}/reject`)
const editContact = async (companyId, contactId, data) => put(`${url}/${companyId}/contacts/${contactId}/update`, data)
const editOwner = async (companyId, ownerId, data) => put(`${url}/${companyId}/ownership-list/${ownerId}/update`, data)
const editBoardMember = async (companyId, memberId, data) => put(`${url}/${companyId}/board-members/${memberId}/update`, data)
const editAsset = async (companyId, assetId, data) => put(`${url}/${companyId}/assets/${assetId}/update`, data)
const editTrigger = async (companyId, triggerId, data) => put(`${url}/${companyId}/triggers/${triggerId}/update`, data)
const fetchFollowedCompanyList = async () => get(`${process.env.VUE_APP_API_URL}/users/fetchFollowedCompanies`)
const getTrendingCompanies = async () => get(`${url}/trending-companies`)
const getMostDevelopedCompanies = async () => get(`${url}/most-developed-companies`)
const fetchCertifiedCompanyList = async () => get(`${process.env.VUE_APP_API_URL}/fetch-certified-companies`)
const fetchIPOCompanyList = async () => get(`${process.env.VUE_APP_API_URL}/fetch-ipo-companies`)
const fetchIPO = async (companyId) => get(`${url}/${companyId}/ipo`)
const fetchDividendHistory = async (companyId)=> get(`${url}/${companyId}/dividend-history`)
const saveIPO = async (companyId, data) => post(`${url}/${companyId}/ipo`, data)
const fetchLinkables = async (data) => get(`${process.env.VUE_APP_API_URL}/fetch-linkables`, data)
const savePrivate = async (companyId, data) => post(`${url}/${companyId}/private`, data)
const saveHiddenPage = async (companyId, data) => post(`${url}/${companyId}/save-hidden-pages`, data)
const fetchPrivateCompanyList = async () => get(`${process.env.VUE_APP_API_URL}/fetch-private-companies`)
const fetchPrivate = async (companyId) => get(`${url}/${companyId}/private`)
const fetchLists = async (companyId) => get(`${url}/${companyId}/lists`)
const updateNotificationSettings = async (companyId, data) => put(`${url}/${companyId}/notifications`, data)
const notificationSettings = async (companyId) => get(`${url}/${companyId}/notifications`)
const addCompanyToList = async (companyId, data) => post(`${url}/${companyId}/add-to-list`, data)
const removeCompanyFromList = async (companyId, data) => post(`${url}/${companyId}/remove-from-list`, data)
const fetchCompanyPlans = async (companyId) => get(`${url}/${companyId}/active-plans`)

const sendInvitation = async (id, data) => post(`${process.env.VUE_APP_API_URL}/company-invitations/${id}/send-invitation`, data)
const fetchInvitations = async (id) => get(`${process.env.VUE_APP_API_URL}/company-invitations/${id}/fetch-invitations`)
const deleteInvitation = async (companyId, id) => destroy(`${process.env.VUE_APP_API_URL}/company-invitations/${companyId}/${id}/destroy`)

const totalNumberOfCompanies = async () => get(`${process.env.VUE_APP_API_URL}/total-number-of-companies`)
const toggleTrialPeriodOnBoarding = async (id) => post(`${url}/${id}/toggle-trial-period-on-boarding`)
const toggleAgreementRequired = async (id) => post(`${url}/${id}/toggle-agreement-required`)
const setDiscountTillDate = async (id, data) => post(`${url}/${id}/set-discount-till-date`, data)
const assignRole = async (id) => post(`${url}/${id}/assign-role`)

const fetchCoupons = async () => get(`${process.env.VUE_APP_API_URL}/fetch-coupons`)
const addCouponToSubscription = async (subscriptionId, data) => post(`${process.env.VUE_APP_API_URL}/subscription/${subscriptionId}/add-coupon`, data)
const fetchUsersCompanies = async (data) => get(`${url}/list-users-companies`, data)
const fetchInUniverseCompanies = async (data) => get(`${url}/fetch-in-universe-companies`, data)
const fetchTopFollowedCompanies = async	() => get(`${process.env.VUE_APP_API_URL}/users/fetch-top-followed-companies`)
const fetchOtherUserTopFollowedCompanies = async (id) => get(`${process.env.VUE_APP_API_URL}/users/${id}/fetch-user-top-followed-companies`)
const fetchBoardOfDirectors = async (id) => get(`${url}/${id}/board-members`)
const saveBoardOfDirectors = async (id, payload) => post(`${url}/${id}/board-members`, payload)
const updateBoardOfDirectors = async (companyId, directorId, payload) => put(`${url}/${companyId}/board-members/${directorId}/update`, payload)
const deleteBoardOfDirectors = async(companyId, directorId) => destroy(`${url}/${companyId}/board-members/${directorId}/delete`)
const relatedPremiumCompanies = async (companyId) => get(`${url}/${companyId}/related-premium-companies`)
const fetchRequests = async (companyId) => get(`${baseUrl}/company-user-requests/companies/${companyId}/get-pending-approvals`)
const approveRequest = async (requestId) => post(`${baseUrl}/company-user-requests/${requestId}/approve`) 
const deleteRequest = async (requestId) => destroy(`${baseUrl}/company-user-requests/${requestId}/delete`)


export default {
  deleteCompanyBanner,
  deleteCompanyLogo,
  updateUSPDescription,
  updateReasonToInvestDescription,
  premiumCompanies,
  fetchInUniverseCompanies,
  fetchUsersCompanies,
  assignRole,
  reportIncident,
  setDiscountTillDate,
  toggleAgreementRequired,
  toggleTrialPeriodOnBoarding,
  addCouponToSubscription,
  fetchCoupons,
  deleteBusinessAnnualDetail,
  totalNumberOfCompanies,
  sendInvitation,
  fetchInvitations,
  deleteInvitation,
  fetchCompanyPlans,
  fetchLists,
  addCompanyToList,
  removeCompanyFromList,
  instrumentsList,
  fetchSectorAndValuation,
  fetchIPO,
  deleteBusinessCSRDetail,
  fetchBusinessCSRDetail, 
  updateBusinessDetailAnnualReport,
  saveNewBusinessAnnualReportDetail,
  fetchDividendHistory,
  fetchCompanyUsers,
  addUsersToCompany,
  uploadLogo,
  uploadBanner,
  setLogoBackground,
  removeUsersRole,
  saveIPO,
  savePrivate,
  fetchPrivateCompanyList,
  fetchPrivate,
  saveHiddenPage,
  list,
  fetchBusinessGrowthDetail,
  updateBusinessDetailGrowth,
  updateBusinessDetailCSR,
  one,
  fetchBusinessAnnualReportDetail,
  updateNotificationSettings,
  notificationSettings,
  listMedia,
  scrollMedia,
  deleteDocument,
  mediaLike,
  store,
  deleteBusinessRndDetail,
  saveNewBusinessCSRDetail,
  saveNewBusinessRndDetail,
  fetchBusinessRndDetail,
  updateBusinessRndDetail,
  deleteBusinessGrowthDetail,
  fetchMedia,
  addComment,
  commentLike,
  commentList,
  shareWithGeneral,
  fetchSubComment,
  addSubComment,
  shareWithCompany,
  addBoardMember,
  addContact,
  addTrigger,
  updateDocument,
  addDocument,
  saveNewBusinessGrowthDetail,
  boardMemberList,
  triggerList,
  contactList,
  documentList,
  assetList,
  addAsset,
  dataList,
  addEvent,
  eventList,
  memberList,
  follow,
  followMultiple,
  agree,
  disagree,
  userRequestList,
  contactDelete,
  approveContact,
  rejectContact,
  triggerDelete,
  triggerDeleteRequest,
  // reportTrigger,
  assetDelete,
  assetDeleteRequest,
  boardMemberDelete,
  approveTrigger,
  rejectTrigger,
  approveAsset,
  rejectAsset,
  approveBoardMember,
  rejectBoardMember,
  approveOwner,
  rejectOwner,
  shareTriggerWithAll,
  shareTriggerWithCompany,
  savePressRelease,
  saveTrigger,
  saveAsset,
  saveDocument,
  ownerDelete,
  addOwner,
  ownersList,
  companyDataList,
  fetchCompanyDiscussions,
  scroll,
  scrollInstruments,
  addCompanyDetails,
  fetchCompanyDetails,
  addCompanyMarketGoals,
  fetchCompanyMarketGoals,
  addCompanyCompetitors,
  fetchCompanyCompetitors,
  addUnknownCompanyCompetitors,
  getBrokerStats,
  statistics,
  fetchFinancials,
  fetchMarketCap,
  saveBurnrate,
  saveCash,
  deleteMedia,
  reportMedia,
  mediaDeleteRequest,
  approveDeleteMedia,
  rejectDeleteMedia,
  contactDeleteRequest,
  boardMemberDeleteRequest,
  ownerDeleteRequest,
  eventDelete,
  eventDeleteRequest,
  approveEvent,
  rejectEvent,
  updateMedia,
  editContact,
  editOwner,
  editBoardMember,
  editAsset,
  editTrigger,
  fetchFollowedCompanyList,
  fetchCertifiedCompanyList,
  getTrendingCompanies,
  getMostDevelopedCompanies,
  fetchIPOCompanyList,
  fetchLinkables,
  brokerList,
  getBrokerStatsByBroker,
  saveSocialTags,
  saveNewManagement,
  updateManagement,
  deleteManagement,
  saveNewReasonToInvest,
  updateReasonToInvest,
  deleteReasonToInvest,
  saveNewBusinessDetail,
  updateBusinessDetail,
  deleteBusinessDetail,
  updateContactInfo,
  updateLocations,
  deleteLocation,
  toggleTrialPeriod,
  toggleWidget,
  toggleCompanyStrategy,
  updateCompanySocial,
  deleteCompanySocial,
  newDocumentListFile,
  deleteDocumentListFile,
  updateDocumentListFile,
  fetchTopFollowedCompanies,
  fetchOtherUserTopFollowedCompanies,
  fetchBoardOfDirectors,
  saveBoardOfDirectors,
  updateBoardOfDirectors,
  deleteBoardOfDirectors,
  fetchRequests,
  approveRequest,
  deleteRequest,
  relatedPremiumCompanies
}
