<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectTransaction({transactionId: notification?.notifiable_id})">
          <div>
            {{ 'Your ' + notification.notifiable.damWalletTransaction.type + ' of ' +
              notification.notifiable.damWalletTransaction.amount + ' ' +
              notification.notifiable.damWalletTransaction.currency + ' is '
              + notification.notifiable.damWalletTransaction.status }}
          </div>
          <div class="text-xs mr-6">
            <span class="font-bold">
              {{ time }}</span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationDamWalletTransactionUpdate',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => { } },
    time: { type: String, default: '' }
  },
  emits: [
    'remove'
  ],
}
</script>
