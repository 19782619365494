<template>
  <div v-if="notification?.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div
          @click="$parent.redirectTransaction({transactionId: notification?.notifiable?.dam_wallet_transaction?.id, notificationId: notification.id})"
        >
          <div class="text-sm pr-4">
            Your deposit of {{ $parent.abbreviateDecimal(notification?.notifiable?.dam_wallet_transaction?.amount) }} {{ notification?.notifiable?.dam_wallet_transaction?.currency }} has not yet arrived. Please make sure that you bank transfer was made correctly. Click here to view detailed instructions.
          </div>
          <div class="text-xs font-bold mt-1 mr-6">
            {{ time }}
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationFundsNotDeposited',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
],
}
</script>
