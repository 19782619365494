<template>
  <div class="page flex flex-grow flex-shrink-0 flex-col bg-main-background dark:bg-custom-purple-500">
    <main class="flex flex-col flex-grow flex-shrink-0">
      <RouterView :key="$route.fullPath" />
    </main>
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>
import { PortalTarget } from 'vue3-portal'
export default {
  name: 'DefaultLayout',
  components: {
    PortalTarget
  }
}
</script>
