<template>
  <div
    class="mx-auto dark:text-primary-dark"
    :class="[customClass, padding, bottomPadding ? '' : '', width]"
  >
    <slot />
  </div>
</template>

<script>
import { isMobile } from '@/js/helpers/mobile'

export default {
  name: 'Container',
  props: {
    padding: { type: String, default: 'py-6 px-0' },
    bottomPadding: { type: Boolean, default: false },
    customClass: { type: String, default: '' },
    full: { type: Boolean, default: false },
    wide: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    topMenu: { type: Boolean, default: false },
  },
  computed: {
    width () {
      if (this.full) {
        return 'w-full'
      }

      if (this.wide) {
        if(isMobile()) return 'w-12/12 xl:w-8/12'
        else return 'w-11/12 xl:w-8/12 lg:min-w-[980px] max-w-[1250px]'
      }

      if (this.medium) {
        return 'w-11/12 2xl:w-11/12'
      }

      if(this.topMenu) {
        return 'w-11/12 xl:w-8/12'
      }

      return 'w-11/12 sm:w-8/12 md:w-6/12 lg:w-5/12 lg:min-w-[980px] max-w-[1250px]'
    }
  }
}
</script>
